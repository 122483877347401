.roll-button button {
  position: relative;
  top: 40vh;
 
  height: 2em;
  width: 6em;
  font-size: 1.5em;
  outline: none;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  background-color: rgb(252, 169, 3);
  color: white;
  transition: all 500ms ease;
}

button:hover {
  background-color: rgb(29, 29, 29);
}

.game {
  position: relative;
  width: auto;
  height: 100%;
  margin-top: -200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}

.wrapper-container {
  position: relative;
  display: inline-block;
}

.dice {
  position: absolute;
  width: 100px;
  height: 100px;
  transform-style: preserve-3d;
  transition: transform 1s;
}

.dot {
  position: absolute;
  width: 20px;
  height: 20px;
  margin: -10px 5px 5px -10px;
  border-radius: 20px;
  background-color: #ffffff;
  box-shadow: inset 2px 2px #c2c2c2;
}

.dice-one {
  position: absolute;

  top:100px;
}

.dice-two {
  position: absolute;
 
}

.side {
  position: absolute;
  background-color: #000000;
  border-radius: 5px;
  width: 100px;
  height: 100px;
  border: 1px solid #272727;
  text-align: center;
  line-height: 2em;
}

.side:nth-child(1) {
  transform: translateZ(3.1em);
}

.side:nth-child(6) {
  transform: rotateY(90deg) translateZ(3.1em);
}

.side:nth-child(3) {
  transform: rotateY(-90deg) translateZ(3.1em);
}

.side:nth-child(4) {
  transform: rotateX(90deg) translateZ(3.1em);
}

.side:nth-child(5) {
  transform: rotateX(-90deg) translateZ(3.1em);
}

.side:nth-child(2) {
  transform: rotateY(-180deg) translateZ(3.1em);
}

.show-1 {
  transform: rotateX(720deg) rotateZ(-720deg);
}

.show-6 {
  transform: rotateX(-900deg) rotateZ(1080deg);
}

.show-3 {
  transform: rotateY(-450deg) rotateZ(-1440deg);
}

.show-4 {
  transform: rotateY(810deg) rotateZ(720deg);
}

.show-5 {
  transform: rotateX(-810deg) rotateZ(-1080deg);
}

.show-2 {
  transform: rotateX(450deg) rotateZ(-720deg);
}

.two-1,
.three-1,
.four-1,
.five-1,
.six-1 {
  top: 20%;
  left: 20%;
}

.four-3,
.five-3,
.six-4 {
  top: 20%;
  left: 80%;
}

.one-1,
.three-2,
.five-5 {
  top: 50%;
  left: 50%;
}

.four-2,
.five-2,
.six-3 {
  top: 80%;
  left: 20%;
}

.two-2,
.three-3,
.four-4,
.five-4,
.six-6 {
  top: 80%;
  left: 80%;
}

.six-2 {
  top: 50%;
  left: 20%;
}

.six-5 {
  top: 50%;
  left: 80%;
}

.footer-sec {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-top: 5em;
}

.footer-sec .footer-icons a {
  display: inline-block;
  text-decoration: none;
  color: #1f1f1f;
  margin-right: 10px;
}
.footer-sec .footer-icons a i {
  display: block;
  height: 60px;
  width: 60px;
  font-size: 24px;
  color: #1f1f1f;
  line-height: 64px;
  text-align: center;
  border-radius: 40px;
  background: transparent;
  -webkit-transition: all 0.3s ease !important;
  -o-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
}

.footer-sec .footer-icons a i.la-facebook-f:hover,
.footer-sec .footer-icons a i.la-facebook-f:focus {
  color: #ffffff;
  -webkit-box-shadow: 0 0 15px 30px #3d558f inset;
  box-shadow: 0 0 15px 30px #3d558f inset;
}
.footer-sec .footer-icons a i.la-twitter:hover,
.footer-sec .footer-icons a i.la-twitter:focus {
  color: #ffffff;
  -webkit-box-shadow: 0 0 15px 30px #33c9dd inset;
  box-shadow: 0 0 15px 30px #33c9dd inset;
}
.footer-sec .footer-icons a i.la-google:hover,
.footer-sec .footer-icons a i.la-google:focus {
  color: #ffffff;
  -webkit-box-shadow: 0 0 15px 30px #d74937 inset;
  box-shadow: 0 0 15px 30px #d74937 inset;
}
.footer-sec .footer-icons a i.la-linkedin-in:hover,
.footer-sec .footer-icons a i.la-linkedin-in:focus {
  color: #ffffff;
  -webkit-box-shadow: 0 0 15px 30px #0474bc inset;
  box-shadow: 0 0 15px 30px #0474bc inset;
}

.footer-sec .footer-icons a i.la-instagram:hover,
.footer-sec .footer-icons a i.la-instagram:focus {
  color: #ffffff;
  background-image: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  animation: 1s instaRound;
}
.footer-sec .footer-icons a i.la-envelope:hover,
.footer-sec .footer-icons a i.la-envelope:focus {
  color: #ffffff;
  -webkit-box-shadow: 0 0 15px 30px #d45049 inset;
  box-shadow: 0 0 15px 30px #d45049 inset;
}

@-webkit-keyframes instaRound {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes instaRound {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes ripple {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes ripple {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.intro-banner-vdo-play-btn .ripple:nth-child(2) {
  animation-delay: 0.3s;
  -webkit-animation-delay: 0.3s;
}
.intro-banner-vdo-play-btn .ripple:nth-child(3) {
  animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
}

.content-wrap {
  padding: 20px;
}

.row {
  margin: 0 -15px;
}

.col-xl-6, .col-lg-8, .col-lg-3, .col-xl-3, .col-lg-9, .col-xl-9 {
  padding: 0 15px;
}

.text-center {
  text-align: center;
}

.mb-5 {
  margin-bottom: 3rem;
}

.h1 {
  font-size: 2.5rem;
  font-weight: bold;
}

.fw-bold {
  font-weight: bold;
}

.p-5 {
  padding: 3rem;
}

.rounded-5 {
  border-radius: 1.25rem;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
}

.info-box {
  height: auto;
}

.info-text {
  font-weight: 300;
  opacity: 0.7;
  color: #000;
  text-shadow: 0 -4px 20px rgba(0, 0, 0, 0.25);
}

.sign-up-text {
  font-family: 'Comic Sans MS', 'Chalkboard SE', 'Comic Neue', sans-serif;
  color: #000;
  font-size: 20px;
  margin-top: -10px;
}

.feature-text {
  font-weight: 200;
  opacity: 0.5;
  color: #000;
  text-shadow: 0 -4px 20px rgba(0, 0, 0, 0.25);
  margin-top: -10px;
  font-size: 18px;
  text-align: center;
}

.button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.button {
  border-radius: 3px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.dark {
  background-color: rgb(255, 255, 255);
}

.game-title {
  font-family: 'Comic Sans MS', 'Chalkboard SE', 'Comic Neue', sans-serif;
  color: #000;
  font-size: 25px;
  margin-top: -10px;
}

.score-text {
  color: rgb(154, 154, 154);
  font-size: 18px;
}
