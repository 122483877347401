.popup-logo {
  position: absolute;
  top: -20px; 
  left: -9px; 
  width: 500px;
  height: 500px; 
}
.popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
  color: rgb(11, 30, 104);
}

.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure it appears above other content */
  }
  
  .popup-content {
    background-color: #fff; /* White background */
    max-width: 600px;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative;
    z-index: 10000; /* Higher than popup-container to ensure it's on top */
  }
  
  .popup-logo {
    height: 80px;
    width: 80px;
    margin-bottom: 20px;
  }
  .popup-desc{
    font-size: 20px;

    font-weight: bold;
    color: #0b1e68;
  }
  .popup-title {
    font-size: 24px;

    font-weight: bold;
    color: #0b1e68;
  }
  
  .popup-description {
    font-size: 16px;
    color: #8d8d8d;
    margin-bottom: 20px;
  }
  
  .popup-buttons {
    display: flex;
    justify-content: center;
  }
  
  .popup-button {
    padding: 10px 20px;
    margin: 0 10px;
    border: none;
    background-color: #0b1e68;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .popup-button:hover {
    background-color: #092251;
  }
  
  .popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    color: #8d8d8d;
    cursor: pointer;
  }
  

  /* PopupModal.css */

.popup-input {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom:20px;;
  }
  
  .popup-input input[type="text"] {
    flex: 1; /* Take remaining space */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 20px 0 0 20px;
  }
  
  .popup-input button {
    padding: 10px 20px;
    border: none;
    border-radius: 0 20px 20px 0;
    background-color: rgb(9, 23, 79);
    color: white;
    cursor: pointer;
  }
  .error-text {
    margin-top: 5px;
    font-size: 14px;
    color: red;
  }
  